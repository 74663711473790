const isUserLoggedInTradingApp = () => {
    const accessToken = localStorage.getItem('morpher-access-token');

    if (accessToken) return true;

    return false;
}
const userWalletType = () => {
    const walletType = localStorage.getItem('walletType');
    if (walletType) return walletType;
    return '';
}

export const hotjarPage = (page) => {
	if (window.hj) {
		window.hj('stateChange', '/trade' + page);
	}
}


export const disableHotJar = async () => {
	if (window.hj) {
		console.log('window.hj', window.hj)
	}
}

export const updateHotJarUser = async (eth_address) => {
	if (window.hj) {
		window.hj('identify', eth_address, {});
	}
}

export const installHotJar = async (eth_address) => {
	if (!document.getElementById('script_hotjar') && !document.getElementById('script_hotjar_injected')) {
		if (process.env.VUE_APP_ENV === 'production') {
			const scriptHTML = "     (function(h,o,t,j,a,r){ \n \
				h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)}; \n \
				h._hjSettings={hjid:3058011,hjsv:6}; \n \
				a=o.getElementsByTagName('head')[0]; \n \
				r=o.createElement('script');r.async=1; \n \
				r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv; \n \
				a.appendChild(r); \n \
			})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');"


			const hotjarScript = document.createElement('script');
			hotjarScript.setAttribute('id', 'script_hotjar');
			hotjarScript.innerHTML = scriptHTML
			document.head.appendChild(hotjarScript);
		} else {
			const scriptHTML = "     (function(h,o,t,j,a,r){ \n \
				h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)}; \n \
				h._hjSettings={hjid:3067715,hjsv:6}; \n \
				a=o.getElementsByTagName('head')[0]; \n \
				r=o.createElement('script');r.async=1; \n \
				r.id='script_hotjar_injected'; \n \
				r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv; \n \
				a.appendChild(r); \n \
			})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');"


			const hotjarScript = document.createElement('script');
			hotjarScript.setAttribute('id', 'script_hotjar');
			hotjarScript.innerHTML = scriptHTML
			document.head.appendChild(hotjarScript);

			if (eth_address) {
				window.setTimeout(() => {
					if (window.hj) {
						var userId = eth_address || null; // Replace your_user_id with your own if available.
						window.hj('identify', userId, {});
						console.log('id done')
					}
				}, 1000)
			}
		}
	}
}

export {
    isUserLoggedInTradingApp,
    userWalletType
}